import isEqual from 'fast-deep-equal';
import { useCallback, useMemo, useRef } from 'react';
import { shallowEqual } from 'react-redux';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getUserSetting, updateUserSetting } from 'store/modules/user';

// Typescript couldnt discriminate typeof function properly
// https://github.com/microsoft/TypeScript/issues/37663#issuecomment-1053831482
const isFunction = (x: unknown): x is Function => typeof x === 'function';

const useUserStorage = <T = any>(
  key: string,
  initialValue: T
): [T, (action: T | ((arg: T) => T)) => void] => {
  // set it in a ref to ensure it doesnt trigger a re-render
  // if an object is passed
  const initialValueRef = useRef(initialValue);

  const userSetting: T | undefined = useAppSelector(
    (state) => getUserSetting(state, key),
    shallowEqual
  );
  const dispatch = useAppDispatch();

  const handleUpdate = useCallback(
    (arg: T | ((arg: T) => T)) => {
      const value: T = isFunction(arg)
        ? arg(userSetting ?? initialValueRef.current)
        : arg;

      if (isEqual(value, userSetting)) {
        return;
      }

      dispatch(updateUserSetting({ key, value }));
    },
    [dispatch, key, userSetting]
  );

  const value = useMemo(
    () => userSetting ?? initialValueRef.current,
    [userSetting]
  );

  return [value, handleUpdate];
};

/**
 * Known keys, migrate or replace if the data structure is changing
 * @see migrations/user-settings/migrate.ts
 */
export const CALENDAR_BY_DAY_ACCESSORIES = 'calendar_byday_accessories';
export const CALENDAR_BY_DAY_RESOURCES = 'calendar_byday_resources'; // appended a url_key
export const CALENDAR_BY_WEEK_RESOURCE = 'calendar_byweek_resource';
export const CALENDAR_BY_DAY_PHYSICIANS = 'calendar_byday_physicians';
export const CALENDAR_DIAG_BY_DAY_PHYSICIANS = 'calendar_diag_byday_physicians';
export const CALENDAR_DIAG_BY_DAY_LOCATIONS = 'calendar_diag_byday_locations';
export const CALENDAR_ZOOM = 'calendar_zoom_'; // appended by resource flags
export const CALENDAR_ZOOM_BY_WEEK = 'calendar_zoom_byweek_'; // appended by resource flags
export const CALENDAR_ZOOM_CARE_PLAN = 'calendar_zoom_bypatient_'; // appended by planning or treatment
/** @deprecated replaced with column count */
export const CALENDAR_MO_RESOURCE_WIDTH = 'calendar_mo_resource_width';
export const CALENDAR_MO_RESOURCE_COLUMNS = 'calendar_mo_resource_columns';

export const PATIENT_PLANS_SHOW_VALIDATED = 'patient_plans_show_validated';

export const RO_TASKS_SHOW_LATEST_JOBS = 'ro_tasks_show_latest_jobs';
export const RO_TASKS_SCOPE = 'ro_tasks_scope';
export const RO_TASKS_EXCLUDE_USERS = 'ro_tasks_exlude_users';
export const RO_TASKS_ASSIGNED_TO_ME = 'ro_tasks_assigned_to_me';
export const RO_TASKS_SORTING = 'ro_tasks_sorting';

export const RO_PATIENT_DETAIL_RECENT_LIST = 'ro_patient_detail_recent_list';

export const APPOINTMENTS_TABLE_COLUMN_ORDERING = 'appointments_table_ordering';

export const MO_APPTS_TABLE_COLUMN_ORDERING = 'mo_appointments_table_ordering';
export const MO_APPOINTMENTS_ROW_SORTING = 'mo_appointments_sorting';
/** @deprecated use generic REPORTS_ROW_SORTING */
export const MO_REPORTS_ROW_SORTING = 'mo_reports_sorting';
/** @deprecated use generic APPOINTMENTS_READONLY_TABLE_ORDERING */
export const MO_APPOINTMENTS_READONLY_TABLE_ORDERING =
  'mo_appointments_ro_table_ordering';
export const MO_AGENDA_TABLE_SORTING = 'mo_appointments_table_sorting'; // asc or desc
export const MO_CALENDAR_ROSTER_GROUP = 'mo_calendar_roster_group';

export const REPORTS_ROW_SORTING = 'reports_sorting';
export const APPOINTMENTS_READONLY_TABLE_ORDERING =
  'appointments_table_readonly_ordering';

export const AUTOSAVE_APPT_STATUS_CHANGE = 'autosave_appt_status_change';

export const APPT_STATUS_FILTER__CALENDARS = 'apt_status_filter_calendars';
export const APPT_STATUS_FILTER__PATIENT_DETAIL =
  'apt_status_filter_patient_detail';
export const PATIENT_PLAN_STATUS_FITLER__PATIENT_DETAIL =
  'patient_plan_status_filter__patient_detail';

export const CONCIERGE_RESCHEDULING_LIST_SORTING =
  'concierge_rescheduling_sorting';

export const DIAGNOSTIC_APPOINTMENTS_TABLE_COLUMN_ORDERING =
  'diagnostic_appointments_table_ordering';

export default useUserStorage;
