import { PATIENT_PLAN_STATUS_FITLER__PATIENT_DETAIL } from 'hooks/useUserStorage';
import { PatientPlanStatus, User } from 'types/dataTypes';

export const addInterruptedPatientPlanStatus = (data: User['settings']) => {
  // Adding interrupted status to patient plan status filter
  if (data[PATIENT_PLAN_STATUS_FITLER__PATIENT_DETAIL]) {
    data[PATIENT_PLAN_STATUS_FITLER__PATIENT_DETAIL] = {
      ...data[PATIENT_PLAN_STATUS_FITLER__PATIENT_DETAIL],
      // inherit from cancelled status
      [PatientPlanStatus.Interrupted]:
        data[PATIENT_PLAN_STATUS_FITLER__PATIENT_DETAIL][
          PatientPlanStatus.Cancelled
        ] ?? true,
    };
  }

  return data;
};
